import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';

import ButtonLink from '../components/links/button-link'
import Icon from '../components/icon';
import ScrollIndicator from '../components/scroll-indicator';


import FeaturedProjects from '../components/featured-projects';
import { StyledH1, StyledH2 } from '../components/_shared/styled-headings';
import { StyledFullHeightSection } from '../components/_shared/styled-section';
import StyledSkewedSection from '../components/skewed-section';


// IMAGES
// import HeroImage from '../images/small-business.svg'
import WebDeveloper from '../images/web-developer.svg'
import SEOImage from '../images/SEO.svg'

// ICONS
import MobileDesign from '../assets/icons/mobile-design.svg';
import ResponsiveDesign from '../assets/icons/responsive-design.svg';
import Optimize from '../assets/icons/optimize.svg';
import Subscription from '../assets/icons/subscription.svg';
import Test from '../assets/icons/test.svg';
import Graph from '../assets/icons/seo.svg';

const Hero = styled.div`
  padding: 6vw 6vw 0 6vw;

  display: grid;
  grid-template-areas: "left";
  grid-template-columns: 1fr;

  ${mq.gt.sm} {
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "left right";
    align-items: center;
  }

  gap: 6vw;

  font-size: clamp(1rem, 10vw, 2rem); 

  min-height: 50vh;

  & .leftColumn {
    grid-area: left;


    display: flex;
    flex-direction: column;
    gap: 4vh;
  }

  & .rightColumn {

    grid-area: left;
    opacity: 0.15;
    z-index: -1;
    ${mq.gt.sm} {
      grid-area: right;
      opacity: 1;
    }
  
    svg {
      width: 100%;
      max-height: 80vh;
    }
  }
  


  

`

const StyledH1Smaller = styled(StyledH1) `
    font-size: 1em;

  ${mq.gt.md} {
    font-size: 1em;
  }


`

const SubTitle = styled.span` 
    font-size: 0.5em;
`

const StyledFullHeightSection1 = styled(StyledFullHeightSection)`
  scroll-snap-type: y proximity;

`

const CardGrid = styled.div`
  display: grid;
  gap: 8vh;

  ${mq.gt.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

const Row = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 2rem;
  margin-bottom: 10vh;


  ${mq.gt.sm} {
    grid-template-columns: 3fr 2fr;

    &:nth-child(even) {
     div {

        order: 2;
      
     }

    }

  }

  & svg {
    width: 100%;
    height: auto;
  }
`;



const Card = ({image, title, text}) => {

  const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    max-width: 64ch;
    margin: 0 auto;
  `

  const CardImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    

    & svg {
      fill: white;
      max-height: 4rem;
      max-width: 4rem;
    }

    & svg .color {
      fill: var(--primary-color)
    }
  `

  const Image = () => {
    switch(image) {
      case "mobile-design":
        return <MobileDesign />;
      case "responsive-design":
        return <ResponsiveDesign />;
      case "optimize":
        return <Optimize />;
      case "subscription":
        return <Subscription />
      case "test":
        return <Test />
      case "seo":
        return <Graph />
          

      default:
        return <div></div>
    }
  }

  const CardInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      text-align: center;
    }
  `

  const CardTitle = styled.span`
    font-size: clamp(1.25rem, 2vw, 4vw);
    font-weight: 800;
    text-align: center;
  `

  return(
    <CardBody>
      <CardImage><Image/></CardImage>
      <CardInner>
        <CardTitle>{title}</CardTitle>
        <p>{text}</p>
      </CardInner>
    </CardBody>
  )
}

const TickCard = ({title, text}) => {

  const CardInnerGrid = styled.div ` 
    display: grid;
    grid-template-columns: 5% 95%;
    gap: 0.5em;
  `

  const CardTitle = styled.div`
    font-weight: 600;
  `;
  

  const Ticked = styled.div `
    display: flex;
    justify-content: flex-end;
    align-items: start;
  `

  return(
    <div>
      <CardInnerGrid>
        <Ticked>
          <Icon icon="check-circle" />
        </Ticked>
        <div>
          <CardTitle>{title}</CardTitle>
          <p>{text}</p>
        </div>
      </CardInnerGrid>
    </div>
  )
}

const Highlighter = styled.span`
  color: var(--primary-color);
`

const HeadingContainer = styled.div`
  margin-bottom: 1rem;
`

const MutedText = styled.span`
  color: var(--disabled-color);
`

const SectionInner = styled.section` 
  width: 100%;
  scroll-snap-align: start;
  margin-top: 10vh;
`

const About = styled.div`
  ${mq.gt.sm} {
    text-align: center;
  }

  text-align: center;

  max-width: 64ch;
  margin: 0 auto;


  display: grid;
  place-content: center;
  /* min-height: 60vh; */

`

const Index = ({ data }) => {
 
  return (
    <Layout menuLinks={blogMenuLinks}>
    <SEO title="Business" />


      <Hero>
          <div className="leftColumn">
            <StyledH1Smaller>Small Business Web Design &amp; Development</StyledH1Smaller>
            <SubTitle>Modern, mobile-ready websites for small businesses for as little as $249/mo.</SubTitle>
            {/* <Link to="/questionnaire">Start your project</Link> */}
            <ButtonLink label="Start your project" link="/questionnaire" />
          </div>
          <div className="rightColumn">
            <WebDeveloper/>
          </div>

          <ScrollIndicator />
      </Hero>

      <StyledFullHeightSection1>




        <SectionInner>
          <About>
            <div>
              <h2><Highlighter>What</Highlighter> I do</h2>
              <p>I specialize in creating visually appealing &ldquo;brochure websites&rdquo; for small businesses on a budget. Every line of code is written by hand to ensure the best performance at the lowest cost, which helps bring in more customers to your site and bring more revenue to your business.</p>
              {/* <a>Learn More</a> */}
            </div>
          </About>
				</SectionInner>

        <SectionInner>

          <Row>
            <div>
              <StyledH2>Web Design &amp; Development Done Differently</StyledH2>
              <p>What makes us unique in the development world is that we don&apos;t use WordPress, or page builders. We meticulously write the code line by line, creating a super efficient website. This gives us much more control over the design, and the website performs lightning fast because there's no bloated or messy code that slows it down and causes security holes.</p>
              <p>Websites built by hand will out-perform most WordPress sites and drag and drop page builders which could cost thousands of dollars. When it comes to the web, the faster and more organized websites win.</p>
            </div>       
            <WebDeveloper />
          </Row>

          <Row>
            <div>
              <StyledH2>Search Engine Optimization For {new Date().getFullYear()}</StyledH2>
              <p>We offer a number of the latest search engine optimization techniques for {new Date().getFullYear()}:</p>
              <li>Keyword-Centered Content</li>
              <li>Clean &amp; Organized Code Structure For Web Crawlers</li>
              <li>Mobile-First-Optimized for the Best Experience</li>
              <li>Fully Responsive For Mobile, Tablet &amp; Desktop</li>
            </div>
            <SEOImage />
          </Row>

        </SectionInner>

        <SectionInner>
          <CardGrid>
            <Card
              image="mobile-design"
              title="Mobile-First Design"
              text="We start building your site for mobile devices first, then we add on to it to make tablet and desktop."
            />

            <Card
              image="responsive-design"
              title="Fully Responsive"
              text="Your website will fit all mobile screens sizes, tablets, and desktop sizes so new clients can access your site from anywhere."
            />

            <Card 
              image="optimize"
              title="Optimization"
              text="60% of all internet traffic is on mobile devices, so we optimize your website to perform their best in search engines."
            />

            <Card 
              image="subscription"
              title="Subscription model"
              text="Allows small businesses to achieve fantastic web presence without providing large amounts of funds upfront."
            />

            <Card
              image="test"
              title="Design &amp; Testing"
              text="Quickly deploy an initial landing page to attract customers ASAP, optimizing keywords to generate best possible search engine ranking."
            />

            <Card
              image="seo"
              title="Search Engine Optimization"
              text="Setup professional looking, fully fleshed out attractive business listings in search engines to drive customers to your business."
            />
          </CardGrid>
        </SectionInner>
        </StyledFullHeightSection1>

        <StyledSkewedSection>
          <HeadingContainer>
            <StyledH2>NO MASSIVE FUNDS NECESSARY, ONLY <Highlighter>$249</Highlighter> PER MONTH</StyledH2>
            <MutedText>or $229 on 6 month contract / $199 on 12 month contract</MutedText>
          </HeadingContainer>
          <p><Highlighter>$249</Highlighter> for a standard 5 page small business website with hosting, maintainence and constant SEO updates included.</p>
          <p>
            If you need more than that then we can talk about custom pricing based on the scope of work, number of additional pages, and time involved.
          </p>
          <TickCard
            title="Hosting Fees Included"
            text="Hosting fees are built right into the monthly payment." />
          <TickCard
            title="Unlimited Edits"
            text="Change anything you want at anytime and it will be done that day." />
          <TickCard
            title="SEO optimization"
            text="Constant SEO improvements using advanced auditing software to consistently improve website ranking." />
          <TickCard
            title="Fast Customer Service"
            text="Call direct anytime day or night, no phone trees or automated responses." />
          <TickCard
            title="Web Design &amp; Development"
            text="Includes over 40 hours of design, development, and testing." />
          <TickCard
            title="Citations And Listings"
            text="It's important to have your website linked from other websites, like directories." />
          <TickCard
            title="Google Analytics"
            text="We install Analytics for free to monitor traffic and where it comes from." />
        </StyledSkewedSection>

        <SectionInner>
          <FeaturedProjects featured={data.featuredProjects.nodes} type="client" />

        </SectionInner>




    </Layout>
  );
};

export default Index;
export const query = graphql`
  {

    featuredProjects: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/content/projects/" }, frontmatter: { business: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "D MMMM, YYYY")
          title
          business
          demo_link
          techs
          cover_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`;
